import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useGetLocationByIdQuery } from 'src/store/services/locationApi';
import { useParams } from 'react-router';
import { useGetAssetQuery } from 'src/store/services/assetsApi';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import DownloadDocuments from 'src/components/DownloadDocumentButton/components/DownloadDocuments';
import { useLazyGetAssetFileByLocationIdQuery,  } from '../../store/services/assetsApi';

import EventsTable from '../Events/components/EventsTable';
import AssetsTable from '../../components/AssetsTable';
import CustomAccordion from '../../components/CustomAccordion';
import EditLocationModal from '../../components/Modals/Location/EditLocation';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { ReactComponent as IconEdit } from 'src/assets/EditItem.svg';
import '../../../src/MarkdownStyles.css';

const ReactMarkdown = lazy(() => import('react-markdown'));

const LocationSingle = () => {
  const { t } = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const params = useParams<{ locationId: string }>();

  const [currentPage, setCurrentPage] = useState(() => {
    const saved = localStorage.getItem(`location-${params.locationId}-page`);
    return saved ? parseInt(saved, 10) : 1;
  });

  useEffect(() => {
    localStorage.setItem(`location-${params.locationId}-page`, currentPage.toString());
  }, [currentPage, params.locationId]);

  const [isAccordionOpen, setIsAccordionOpen] = useState(() => {
    const saved = localStorage.getItem(`location-${params.locationId}-accordion`);
    return saved ? saved === 'true' : window.innerWidth >= 768;
  });

  useEffect(() => {
    localStorage.setItem(`location-${params.locationId}-accordion`, isAccordionOpen.toString());
  }, [isAccordionOpen, params.locationId]);

  const [isLocationAccordionOpen, setIsLocationAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [gfm, setGfm] = useState<any>(null); 

  const pageSize = 10;
  const { data: locationData, isLoading: isLocationLoading } = useGetLocationByIdQuery(
    params.locationId,
  );
  const { data: locationAssetsData, isLoading: isLocationAssetsLoading } = useGetAssetQuery({
    pageSize,
    pageOffset: currentPage,
    locationId: params.locationId,
  });

  const [
    triggerLocationPDF,
    { data: pdfLocationData, isLoading: isPdfDataLoading, isFetching: isPdfFetching },
  ] = useLazyGetAssetFileByLocationIdQuery();
  const [
    triggerLocationCSV,
    { data: csvLocationData, isLoading: isCsvDataLoading, isFetching: isCsvFetching },
  ] = useLazyGetAssetFileByLocationIdQuery();


  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default); 
    };

    loadGfm();
  }, []);

  if (isLocationLoading || isLocationAssetsLoading || !gfm) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="font-mukta-mahee text-grey-asset ">
        <h1 className="font-bold text-[32px] my-[22px]">{locationData?.name}</h1>
        <div className="markdown-editor">
          <Suspense fallback={<div>Loading markdown...</div>}>
            <ReactMarkdown remarkPlugins={[gfm]}>
              {locationData?.description}
            </ReactMarkdown>
          </Suspense>
        </div>
        <ul>
          <li>{locationData?.address.streetAddress}</li>
          <li>
            {locationData?.address.postalCode} {locationData?.address.city}
          </li>
          <li>{locationData?.address.country}</li>
        </ul>
        {!!locationAssetsData?.assets.length && (
            <AssetsTable
              data={locationAssetsData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            >
              <DownloadDocuments
                id={params.locationId}
                type="location"
                isCsvFetching={isCsvFetching}
                isPdfFetching={isPdfFetching}
                csvFileUrl={csvLocationData}
                pdfFileUrl={pdfLocationData}
                csvTrigger={triggerLocationCSV}
                pdfTrigger={triggerLocationPDF}
              />
          </AssetsTable>
        )}
      </div>
      <CustomAccordion
        title={t('latest-events-title', { ns: 'batches' })}
        isAccordionOpen={isAccordionOpen}
        setIsAccordionOpen={setIsAccordionOpen}
      >
        <EventsTable defaultId={params.locationId} defaultType={'LOCATION'} pageSize={5} />
      </CustomAccordion>
      <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
          icon={<IconEdit className="w-6 h-6 fill-tag" />}
          text={t('edit-location', { ns: 'location' })}
          onClick={() => setIsModalOpened(true)}
          />

      <EditLocationModal
          onClose={() => setIsModalOpened(false)}
          isOpened={isModalOpened}
        data={locationData}
      />
    </div>
  );
};

export default React.memo(LocationSingle);