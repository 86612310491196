import React, { ReactElement, useEffect } from 'react';

import ButtonWithIcon from './ButtonWithIcon';

import Loading from './Loading';

interface IDownloadButtonProps {
    text: string;
    downloadFilename: string;
    icon: ReactElement;
    trigger: (u: {} | undefined) => void;
    fileUrl: string | undefined;
    isFetching: boolean;
    className?: string;
    data?: {}
}

const DownloadButton = ({text, downloadFilename, icon, trigger, fileUrl, isFetching, className, data}: IDownloadButtonProps) => {

  

  const handleDownload = () => {
    trigger(data);
  };

  useEffect(() => {
    if (fileUrl) {
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch the file');
          }
          return response.blob();
        })
        .then((blob) => {
          // Create a new blob URL and trigger download
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', downloadFilename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((err) => console.error('Download failed:', err));
    }
  }, [fileUrl, downloadFilename]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <ButtonWithIcon
      className={"flex items-center justify-center gap-1 py-[10px] px-5 rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2" + (className ? className : "")}
      icon={icon}
      text={text}
      onClick={handleDownload}
    />
  );
};

export default DownloadButton;