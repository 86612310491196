import { Route, RouteProps } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from 'src/helpers/reduxCommon';
import { setReturnTo } from 'src/store/slices/auth.slice';

const PrivateRoute = (props: RouteProps) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const returnPath = location.pathname + location.search;
      dispatch(setReturnTo(returnPath));
      history.push('/');
    }
  }, [isLoading, isAuthenticated, history, location, dispatch]);

  return !isLoading && isAuthenticated ? <Route {...props} /> : null;
};

export default PrivateRoute;
