import React, {useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { ReactComponent as IconService } from 'src/assets/IconService.svg';
import { useAppSelector } from 'src/helpers/reduxCommon';
import { useGetOrganizationQuery } from '../../store/services/configApi';
import { IOrganizationData, IOrganizationMap } from '../../store/services/configApi/configTypes';
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { useMarkAssetMutation } from 'src/store/services/markApi';
import { useGrantWarrantyMutation } from '../../store/services/warrantyApi';
import { JwtDecodeUsers } from '../../helpers/JwtDecodeUsers';

import { getXAuthOwnerHeader } from '../../helpers/getXAuthOwnerHeader';
import { ReactComponent as IconLogout } from 'src/assets/footer-menu/IconLogout.svg';
import CustomAccordion from '../../components/CustomAccordion';
import { ReactComponent as IconBuild } from 'src/assets/footer-menu/IconBuild.svg';
import { ReactComponent as IconLicense } from 'src/assets/footer-menu/IconLicense.svg';
import FormField from 'src/components/FormField';

interface IServiceAndMaintenance {
  isOpen: boolean;
  assetId?: number | null;
  properties?: Record<string, string>;
  showProvidersFor?: boolean;
  defaultServiceRequestUrl?: string,
  showMarked?: boolean;
}



const ServiceAndMaintenance = ({isOpen, assetId, properties, showProvidersFor, defaultServiceRequestUrl, showMarked }: IServiceAndMaintenance) => {
  const { t } = useTranslation('service');

  const [description, setDescription] = useState<string>('');

  const activeOrg = useAppSelector((state) => state.activeOrg.activeOrg);
  const [skip, setSkip] = React.useState(!activeOrg)
  const { data: organizationsData, isLoading: isOrganizationDataLoading } = useGetOrganizationQuery({}, {skip});
 
  const [markAsset] = useMarkAssetMutation();

  const capitalize = (s: string | null) => s && String(s[0]).toUpperCase() + String(s).slice(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(isOpen);


  const [isMarked, setIsMarked] = useState<boolean>(properties ? 'MARK_SERVICE' in properties : false);
  const [grantWarranty] = useGrantWarrantyMutation();
  const token = useAppSelector((state) => state.auth.token);
  const isServiceProvider = !!JwtDecodeUsers(token, 'https://sirk.app/roles').find(
    (role) => role.label === 'serviceprovider',
  );

  const getOrganizationData = () => organizationsData ? (organizationsData as IOrganizationMap)[activeOrg as string] : {'config': {}} as IOrganizationData;
  const getApprovedServiceProviders = () => organizationsData ? getOrganizationData().config?.approvedServiceProviders : [];
  const getServiceProvidersFor = () => organizationsData ? getOrganizationData().config?.serviceProviderFor : [];
  const getOrgName = (id: string | null) => id && (organizationsData as IOrganizationMap)[id as string] && (organizationsData as IOrganizationMap)[id as string].name;
  const getCreateRequestURLButtonForOrg = (id: string | null) => id && (organizationsData as IOrganizationMap)[id as string].config?.externalServiceRequestUrl && (
    <a className="p-1 px-5 text-black hover:underline " 
       href={ (organizationsData as IOrganizationMap)[id as string].config?.externalServiceRequestUrl  } rel="noreferrer" target="_blank">{ "\u21aa\u00a0" + t('service-manual-request').replace(/ /g, '\u00a0')}</a>
  );
  const getCreateRequesgetCreateRequestURLButton = (url: string) => (
    <a className="p-1 px-5 text-black hover:underline " 
       href={ url } rel="noreferrer" target="_blank">{ "\u21aa\u00a0" + t('service-manual-request').replace(/ /g, '\u00a0')}</a>
  );

  return !isOrganizationDataLoading ? (
    <CustomAccordion
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
          bodyClassName='bg-green-200'
          headerClassName='bg-green-300'
          accordionWrapperClassName="basis-4/5 xl:basis-1/2"
          title={ t('service-header')}
          icon={<IconService className="w-6 h-6 mr-4 " />} 
        >

      {getApprovedServiceProviders() && getApprovedServiceProviders().length ? (
       <div className="p-3 px-5">
        {t('service-providers', {"org": getOrgName(activeOrg)})}
        <ul className="list-disc mx-5">{getApprovedServiceProviders().map((value, index) => 
          (<li key={index}>{getOrgName(value)} { getCreateRequestURLButtonForOrg(value) }</li>)
          )} </ul>
       </div>
      ) : (activeOrg ? <p className="italic p-3 px-5 ">{t('service-no-providers', {"org": getOrgName(activeOrg)})}</p> : <></> ) }

      {(showProvidersFor && getServiceProvidersFor() && getServiceProvidersFor().length) ? (
       <p className="italic p-5"> {t('service-provider-for', {"org": getOrgName(activeOrg), "n": getServiceProvidersFor().length})}</p>
      ) : null }

      {defaultServiceRequestUrl && (
        <p className="p-3">{getCreateRequesgetCreateRequestURLButton(defaultServiceRequestUrl) }</p>
      )}

      {false && properties != null && !(organizationsData as IOrganizationMap)[activeOrg as string].config?.externalServiceRequestUrl && !isMarked && (
        <form>
        <FormField
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setDescription(e.currentTarget.value);
            }}
            type="text"
            as="textarea"
            name={'name'}
            placeholder={t('service-description-placeholder')}
          />

        <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center  border-2 border-solid border-red-800 text-red-800  m-2"
          icon={<IconBuild className="w-6 h-6 fill-red-800 " />}
          text={t('service-mark-for-service')}
          onClick={() => {
            markAsset({
              body: {
                id: assetId,
                description: description,
                type: 'SERVICE'
              }
              
            });
            setIsMarked(true);
          }
        }
      
        />

      </form>
      )}

    
      {properties != null && isMarked && (
      
      <p className="p-4">{t('service-marked-for-service')}</p>
      )}

      {properties != null && isServiceProvider && (
        
        <ButtonWithIcon
        className="flex items-center justify-center gap-1 py-[10px] px-5  my-5  rounded text-center  border-2 border-solid border-black text-black  m-2"
        icon={<IconLicense className="w-6 h-6 fill-black " />}
        text={t('service-grant-warranty')}
        onClick={() => {
          grantWarranty({
            body: {
              assetId: assetId,
              duration: 'THREE_YEARS',
            },
            ...getXAuthOwnerHeader(activeOrg || ''),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    
      />

      )}

    </CustomAccordion>
  ) : (
    <Loading />
  );
};

export default ServiceAndMaintenance;
