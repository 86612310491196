import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { getCategoryMapping } from '../../../../../../helpers/categoriesOptions';
import CategoryBadge from './components/CategoryBadge';
import { ICategories } from '../../../../../../store/services/categoryApi/categoryTypes';
  
interface ITagCategoryDetails {
  properties: Record<string, string>;
  selectedCategories?: string[];
  connectedCategories: string[];
  categoryList?: ICategories;
  isCategoriesSuccess: boolean;
}

export type ITagStates =
  | 'PROVISIONED'
  | 'COMMISSIONED'
  | 'STORED'
  | 'SERVICE'
  | 'TRANSPORT'
  | 'DISCARDED';

const TagCategories = ({
  properties,
  selectedCategories,
  connectedCategories,
  categoryList,
  isCategoriesSuccess,
}: ITagCategoryDetails) => {
  const history = useHistory();

  const categoryMapping = useMemo(
    () =>
      selectedCategories?.length && isCategoriesSuccess
        ? getCategoryMapping(selectedCategories, properties, categoryList?.categoryTypes)
        : [],
    [categoryList, selectedCategories, isCategoriesSuccess, properties],
  );

  const filteredConnectedCategories =
    connectedCategories?.filter(
      (connectedCategory) =>
        !categoryMapping.some(
          (selectedCategory) => selectedCategory.category === connectedCategory,
        ),
    ) || [];

  const handleCategoryClick = (category: string) => {
    history.push(`/search?searchType=category&searchValue=${category}`);
  };

  return (
    <section className="flex justify-start items-center  flex-wrap ">


      {filteredConnectedCategories?.map((connectedCategory, index) => {
        return (
          <CategoryBadge
            key={`${connectedCategory}-${index}`}
            category={connectedCategory}
            handleCategoryClick={() => handleCategoryClick(connectedCategory)}
          />
        );
      })}

      {categoryMapping.map(({category}, index) => {
        return (
          <CategoryBadge
            key={`${category}-${index}`}
            category={category}
            handleCategoryClick={() => handleCategoryClick(category)}
            badgeType="selected"
          />
        ) 
      })}

      
    </section>
  );
};

export default React.memo(TagCategories);
