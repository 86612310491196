import Loading from '../../Loading';
import { ReactComponent as IconQr } from 'src/assets/home/IconQR.svg';
import { ReactComponent as IconDownload } from 'src/assets/IconDownload.svg';
import DownloadButton from 'src/components/DownloadButton';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';


interface IDownloadDocumentsProps {
  id: string;
  type: string;
  isPdfFetching: boolean;
  isCsvFetching: boolean;
  pdfFileUrl: string | undefined;
  csvFileUrl: string | undefined;
  pdfTrigger: (u: {} | undefined) => void;
  csvTrigger: (u: {} | undefined) => void;
  className?: string;
  params?: any;
  children?: ReactNode;  
}

const DownloadDocuments = ({ id, type, isPdfFetching, isCsvFetching, pdfFileUrl, csvFileUrl, pdfTrigger, csvTrigger, className, params, children}: IDownloadDocumentsProps) => {

  const { t } = useTranslation('tag');
  
  if (isPdfFetching || isCsvFetching) {
    return <Loading />;
  }

  return (
    <div className="flex justify-end gap-1">
        {children}
        <DownloadButton 
           icon={<IconDownload className="w-6 h-6 fill-tag" />}
           text={t("document-export-csv")}
           downloadFilename={type + '-' + id + '.csv'}
           trigger={csvTrigger}
           fileUrl={csvFileUrl}
           isFetching={isCsvFetching}
           data={{
              id: id,
              params: params,
              headers: { Accept: 'text/csv' },
          }}
          className="text-[0.9em] px-2 py-1 m-2"
        />

       <DownloadButton 
           icon={<IconQr className="w-6 h-6 fill-tag" />}
           text={t("document-download-qr")}
           downloadFilename={type + '-' + id + '.pdf'}
           trigger={pdfTrigger}
           fileUrl={pdfFileUrl}
           isFetching={isPdfFetching}
           data={{
              id: id,
              params: params,
              headers: { Accept: 'application/pdf' },
          }}
          className="text-[0.9em] px-2 py-1 m-2"
        />

    </div>
  );
};

export default DownloadDocuments;
