import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import CustomSelect from '../../../../components/CustomSelect';
import SearchField from '../../../../components/SearchField';
import { IMultiSelectOptions, IOptions } from '../../../../types';
import { IBatchData } from '../../../../store/services/batchApi/batchTypes';
import { batchTypeOptions } from '../../../../constants/batchTypeOptions';
import { getBatchStateOptions } from '../../../../constants/batchStateOptions';
import i18n from 'i18next';

interface IBatchesFilter {
  batches: IBatchData[] | undefined;
  setBatches: React.Dispatch<React.SetStateAction<IBatchData[] | undefined>>;
  setSelectedStates: React.Dispatch<React.SetStateAction<[] | string[]>>;
  setSelectedTypes: React.Dispatch<React.SetStateAction<[] | string[]>>;
}

const BatchesFilter = ({
  batches,
  setBatches,
  setSelectedStates,
  setSelectedTypes,
}: IBatchesFilter) => {
  const { t } = useTranslation('batches');
  const [selectedStateValue, setSelectedStateValue] = useState<IMultiSelectOptions>([
    { value: 'PLANNED', label: t('batch-state-planned-option') },
    { value: 'IN_PROGRESS', label: t('batch-state-in_progress-option') },
  ]);
  const [selectedTypeValue, setSelectedTypeValue] = useState<IMultiSelectOptions>([]);
  const [batchStateOptions, setBatchStateOptions] = useState<IOptions[]>([]);
  const [originalBatches, setOriginalBatches] = useState<IBatchData[] | undefined>(batches);

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: { search: '' },
    onSubmit: (): void => applyFilters(),
  });

  const applyFilters = useCallback(() => {
    if (!originalBatches) return;

    const searchTerm = values.search.toLowerCase();
    const states = selectedStateValue.map(option => option.value);
    const types = selectedTypeValue.map(option => option.value);

    const filteredBatches = originalBatches.filter((batch) => {
      const matchesSearch = searchTerm === '' || 
        batch.name?.toLowerCase().includes(searchTerm) || 
        batch.description?.toLowerCase().includes(searchTerm);
      const matchesState = states.length === 0 || 
        (batch.state && states.includes(batch.state));
      const matchesType = types.length === 0 || 
        (batch.type && types.includes(batch.type));

      return matchesSearch && matchesState && matchesType;
    });

    setBatches(filteredBatches);
  }, [originalBatches, values.search, selectedStateValue, selectedTypeValue, setBatches]);

  const handleSelectState = (options: IMultiSelectOptions) => {
    setSelectedStateValue(options);
    setSelectedStates(options.map(option => option.value));
    applyFilters();
  };

  const handleSelectType = (options: IMultiSelectOptions) => {
    setSelectedTypeValue(options);
    setSelectedTypes(options.map(option => option.value));
    applyFilters();
  };

  useEffect(() => {
    const loadBatchStates = async () => {
      const options = await getBatchStateOptions();
      setBatchStateOptions(options);
    };

    loadBatchStates();

    const handleLanguageChange = () => {
      loadBatchStates();
      setSelectedStateValue([
        { value: 'PLANNED', label: t('batch-state-planned-option') },
        { value: 'IN_PROGRESS', label: t('batch-state-in_progress-option') },
      ]);
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => i18n.off('languageChanged', handleLanguageChange);
  }, [t]);

  useEffect(() => {
    setOriginalBatches(batches);
    if (batches) {
      applyFilters();
    }
  }, [batches, applyFilters]);

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <SearchField
        value={values.search}
        name="search"
        onChange={handleChange}
        wrapperClassName="mb-2 md:mb-0 rounded-[4px] border-[1px]"
      />
      <div className="flex flex-wrap justify-between gap-x-4 w-full">
        <div className="w-full md:w-[40%]">
          <CustomSelect
            placeholder={t('batch-state-filter-label')}
            isMulti
            value={selectedStateValue}
            options={batchStateOptions}
            onSelect={handleSelectState}
            customStyle="w-full"
          />
        </div>
        <div className="w-full md:w-[40%]">
          <CustomSelect
            placeholder={t('batch-type-filter-label')}
            isMulti
            value={selectedTypeValue}
            options={batchTypeOptions}
            onSelect={handleSelectType}
            customStyle="w-full"
          />
        </div>
      </div>
    </form>
  );
};

export default BatchesFilter;
