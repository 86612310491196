import React, { useState } from 'react';
import { IBatchData } from '../../../../store/services/batchApi/batchTypes';
import BatchRow from './components/BatchRow';
import { Pagination } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

interface IFilteredBatches {
  data: IBatchData[];
  pageSize?: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const BatchesTable = ({ data, pageSize = 15, currentPage, setCurrentPage }: IFilteredBatches) => {
  const { t } = useTranslation('batches');
  
  if (!data || data.length === 0) {
    return (
      <div className="text-center py-4">
        {t('no-results-found')}
      </div>
    );
  }

  const totalPages = Math.ceil(data.length / pageSize);
  
  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(1);
  }

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);
  
  return (
    <>
      <table className="w-full border-collapse text-sm leading-normal !mt-5">
        <thead>
            <tr><th>{t('batch-name-holder')}</th><th className="hidden xl:table-cell">{t('description')}</th><th className="hidden md:table-cell">{t('batch-counter-holder')}</th><th className="hidden lg:table-cell">{t('batch-tag-holder')}</th><th className="hidden md:table-cell">{t('batch-timestamp-holder')}</th><th>{t('batch-type-place-holder')}</th><th>{t('batch-state-holder')}</th></tr>
        </thead>
        <tbody>
          {currentData.map((batch: IBatchData, index: number) => (
            <BatchRow key={index} data={batch} />
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center pb-16 md:pb-2">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            previousLabel="&#60;&#60;"
            nextLabel="&#62;&#62;"
          />
        </div>
      )}
    </>
  );
};

export default BatchesTable;
