import React, { ReactNode } from 'react';
import AssetTableRow from './components/AssetTableRow';
import { IAssetData } from '../../store/services/assetsApi/assetsTypes';
import { Pagination } from 'flowbite-react';
import { useTranslation } from 'react-i18next';


interface IAssetsTable {
  data: IAssetData;
  currentPage: number;
  showLocation?: boolean;
  children?: ReactNode;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  
}

const AssetsTable = ({ data, currentPage, showLocation, setCurrentPage, children }: IAssetsTable) => {

  const { t } = useTranslation('tag'); 
  
  return (
    <>
      <h2 className="border border-0 border-t-2 pt-10 mt-20 text-[1.5em] font-bold">{t('assets',{'ns': 'batches'})}</h2>
      <p>{t("list-total-assets", {"val": data?.xEntriesTotal})}</p>
      <table className="w-full border-collapse !mt-0 text-[1em]">
        <thead>
          <tr>
          <th>Name</th>  
          {showLocation && (
          <th className="hidden xl:table-cell">{t('location', {'ns': 'location'})}</th>
          )}
          <th className="hidden md:table-cell">{t('category', {'ns': 'layout'})}</th>
          <th className="hidden xl:table-cell">{t('service-warranty', {'ns': 'service'})}</th>
          <th>{t('edit_tag_state_placeholder')}</th>
          <th>{t('batch-tag-holder', {'ns': 'batches'})}</th>
          </tr>
        </thead>
        <tbody>
          {data.assets.map((asset) => (
            <AssetTableRow key={asset.id} asset={asset} showLocation={showLocation} />
          ))}
            
        </tbody>
        
      </table>
      {children}
      {data?.xPageTotal > 1 && (
        <div className="flex overflow-x-auto justify-center mt-4">
          <Pagination
            currentPage={currentPage}
            totalPages={data?.xPageTotal}
            onPageChange={setCurrentPage}
            previousLabel="&#60;&#60;"
            nextLabel="&#62;&#62;"
          />
        </div>
      )}
    </>
  );
};

export default AssetsTable;
