import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import ModalContainer from '../../components/Modals/components/ModalContainer';
import Scanner from '../../components/Scanner/Scanner';
import sirkAppChairs from 'src/assets/footer-menu/images/landing_chairs_2.png';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { ReactComponent as IconQR } from 'src/assets/home/IconQR.svg';
import { ReactComponent as IconLogin } from 'src/assets/IconLogin.svg';
import { Trans } from 'react-i18next';
import Footer from './components/Footer';
import { themeConfigs } from '../../constants/themeConfigs';
import { useAppSelector } from 'src/helpers/reduxCommon';

const Landing = () => {
  const { t } = useTranslation('landing');
  const { loginWithRedirect } = useAuth0();
  const returnTo = useAppSelector(state => state.auth.returnTo);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(!!params.get('isOpenScanner'));

  const handleScannerOpen = useCallback(() => setIsScannerOpen(true), []);
  const handleScannerClose = useCallback(() => setIsScannerOpen(false), []);

  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: returnTo || '/'
      }
    });
  };

  return (
    <>
      <div className="flex gap-8 flex-col justify-center items-center p-5 pt-[50px] font-mukta-mahee">
        <div className="before:content-landing-logo-img before:block before:w-full before:h-full before:bg-contain" />
        <ButtonWithIcon
          className="flex items-center gap-[10px] max-w-[188px] min-h-[50px] py-[10px] px-5 bg-tag text-white border-none rounded text-lg leading-normal text-center !outline-transparent transition-colors duration-300 ease-linear"
          icon={<IconQR className="w-6 h-6 fill-white" />}
          text={t('scan-qr')}
          onClick={handleScannerOpen}
        />
        <div className='before:content-landing-chairs-img before:block before:w-full before:h-full before:bg-contain'/>
        <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5  h-[52px] rounded text-lg leading-normal text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear"
          icon={<IconLogin className="w-6 h-6 fill-tag" />}
          text={t('log-in')}
          onClick={handleLogin}
        />
        <p>
          <Trans
            i18nKey={
              themeConfigs[process.env.REACT_APP_THEME || 'defaultTheme'].landingCompanyDescription
            }
            ns="landing"
            components={{
              a: (
                <a
                  className="!underline !decoration-[rgb(85,_26,_139)]"
                  href={
                    themeConfigs[process.env.REACT_APP_THEME || 'defaultTheme']
                      .landingCompanyDescriptionLink
                  }
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      </div>
      <Footer />
      <ModalContainer isModalOpened={isScannerOpen} onClose={handleScannerClose}>
        <Scanner onClose={handleScannerClose} />
      </ModalContainer>
    </>
  );
};

export default Landing;
