import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik, FormikProps } from 'formik';
import FormField from 'src/components/FormField';
import { IEditAssetBodyData } from 'src/store/services/assetsApi/assetsTypes';
import { IDocument, ITagData } from 'src/store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import { usePostAssetByIdMutation } from '../../../../../store/services/tagsApi';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import DocumentPreviewer from './components/DocumentPreviewer';
import Dangerzone from 'src/components/Dangerzone';
import CustomAccordion from '../../../../../components/CustomAccordion';
import { createFormDataFile } from '../../../../../helpers/createFormDataFile';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';
import FormWrapper from 'src/components/FormWrapper';
import { TagValidation } from './tagValidation';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import BatchesSelect from './components/BatchesSelect';
import LocationSelect from './components/LocationSelect';
import CategoriesSelect from './components/CategoriesSelect';
import TagStateSelect from './components/TagStateSelect';
import { getTagInitialValues } from './getTagInitialValues';
import { ServerError } from 'src/store/storeTypes';
import ServerFormError from 'src/components/ServerFormError';
import ExternalIdsMultiselect from './components/ExternalIdsMultiselect';
import CategoryPropertyList from './components/CategoryPropertyList';
import CustomMDEditor from 'src/components/MDEditor';
import ImagePreviewer from './components/ImagePreviewer';
import { useGetCategoriesQuery } from 'src/store/services/categoryApi';
import { getLinkedProperties } from 'src/helpers/categoriesOptions';
import TakePhotoButton from './components/TakePhotoButton';
import UploadDocumentsButton from './components/UploadDocumentButton';
import ImageScanner from 'src/components/ImageScanner';
import { useHistory } from 'react-router-dom'; 
import { ROUTES } from 'src/constants/routes';
import { useDeleteAssetMutation } from 'src/store/services/assetsApi'; 

type IOpenedModalType = '' | 'imageUploader';

interface ITagForm {
  title: string;
  onSubmit: any;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  editAssetData?: ITagData;
  isOpened: boolean;
  onClose: () => void;
  resetEditAsset?: () => void;
  setLocalTagImages?: React.Dispatch<React.SetStateAction<[] | IDocument[]>>;
  data?: ITagData;
}

const TagForm: React.FC<ITagForm> = ({
  title,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  editAssetData,
  onClose,
  resetEditAsset,
  setLocalTagImages,
  data,
}) => {
  const { t } = useTranslation('tag');
  const [tagDocuments, setTagDocuments] = useState<IDocument[]>([]);
  const [openedModalType, setOpenedModalType] = useState<IOpenedModalType>('');
  const [postAssetById] = usePostAssetByIdMutation();
  const assetId = data?.id;
  const initialValues = getTagInitialValues(data);
  const { data: categoryList, isSuccess: isCategoriesSuccess } = useGetCategoriesQuery({});
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const [isUploadAccordionOpen, setIsUploadAccordionOpen] = useState<boolean>(true);
  const history = useHistory(); 
  const [deleteAssetById] = useDeleteAssetMutation();

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
    submitForm,
    resetForm,
  }: FormikProps<IEditAssetBodyData> = useFormik({
    initialValues,
    validationSchema: TagValidation(),
    onSubmit: (values) => {
        const batchesIds = values.batches?.map((batch) => batch.id) || [];
        const documentIds = tagDocuments.map((doc) => doc.id);

        const newAssetBody = {
            name: values.name,
            description: values.description,
            state: values.state,
            categories: values.categories,
            locationId: values.location?.id,
            properties: values.properties,
            ...(values.batches?.length ? { batches: batchesIds } : {}),
            ...(values.properties ? { properties: values.properties } : {}),
            documentIds: [...documentIds],
            deleteFields: [
                ...(!values.externalIds?.length ? ['externalIds'] : []),
                ...(documentIds.length === 0 ? ['documentIds'] : [])
            ].filter(Boolean)
        };

        onSubmit({
            body: newAssetBody,
            assetId: data?.id,
            ...getXAuthOwnerHeader(values.organization),
        });
    },
  });

  const handleClose = useCallback(() => {
    resetForm();
    setTagDocuments(data?.documents || []);
    setOpenedModalType('');
    setIsAccordionOpen(false);
    setIsUploadAccordionOpen(true);
    onClose();
  }, [resetForm, data, onClose]);

  useEffect(() => {
    if (data?.documents) {
      setTagDocuments(data.documents);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      resetEditAsset && resetEditAsset();
      window.location.reload();
    }
  }, [isSuccess, onClose, resetEditAsset]);

  return (
    <ModalContainer isModalOpened={isOpened} onClose={handleClose}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={handleSubmit}>
        <FormField
          type="text"
          value={values.name}
          name="name"
          placeholder={t('edit_tag_name_placeholder')}
          onChange={handleChange}
          isError={!!errors.name && !!touched.name}
          errorMessage={(touched.name && errors?.name) || ''}
        />

        <div className="markdown-editor mb-8">
          <CustomMDEditor
            id="description-editor"
            label={t('edit_tag_description_placeholder')}
            value={values.description || ''}
            onChange={(value) =>
              handleChange({ target: { name: 'description', value } })
            }
          />
        </div>

        <div className="flex flex-col">
          {assetId && (
            <TagStateSelect state={values.state} setFieldValue={setFieldValue} />
          )}
          <BatchesSelect batches={values.batches} setFieldValue={setFieldValue} />
          <LocationSelect location={values.location} setFieldValue={setFieldValue} />
          <CategoriesSelect categories={values.categories} setFieldValue={setFieldValue} />
          <ExternalIdsMultiselect
            tagData={data}
            setFieldValue={setFieldValue}
            placeholder={t('select-tags-placeholder')}
          />
          <CategoryPropertyList values={values} setFieldValue={setFieldValue} />
        </div>

        <OrganizationsSelect
          setFieldValue={setFieldValue}
          value={values.organization}
          isError={!!errors.organization && !!touched.organization}
          errorMessage={(touched.organization && errors.organization) || ''}
          placeholder={t('select-organization-placeholder')}
        />

        <div className="flex justify-center my-20">
          <SaveButton submitFunc={submitForm} disabled={isLoading} />
          <CloseButton onClose={handleClose} disabled={isLoading} />
        </div>

        {assetId && (
        <>
          <ImagePreviewer
            assetId={data?.id ?? 0}
            capturedImages={tagDocuments.filter(doc => doc.documentType === 'IMAGE')}
            setCapturedImages={setTagDocuments}
            dataDocuments={data?.documents}
          />

          <DocumentPreviewer
            tagDocuments={tagDocuments}
            setTagDocuments={setTagDocuments}
          />

          <CustomAccordion
            isAccordionOpen={isUploadAccordionOpen}
            setIsAccordionOpen={setIsUploadAccordionOpen}
            accordionWrapperClassName="my-10"
            contentWrapperClassName="flex items-center justify-center"
            title={t('edit-upload-label', { ns: 'tag' })}
          >
            <TakePhotoButton
              assetId={data?.id ?? 0}
              onImageCapture={(img) => {}}
              buttonText={t('context-menu-take-photo-label', { ns: 'tag' })}
              className="m-5"
            />

            <UploadDocumentsButton
              onUpload={async (formData) => {
                try {
                  const response = await postAssetById({
                    assetId: data?.id,
                    body: formData,
                  }).unwrap();

                  setTagDocuments((prev) => [...prev, response]);
                } catch (error) {
                  console.error('Error during upload:', error);
                }
              }}
              buttonText={t('context-menu-upload-file-label', { ns: 'tag' })}
              className="additional-custom-styles"
            />
          </CustomAccordion>
        </>
      )}

        {assetId && (
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title={t('dangerzone-label', { ns: 'tag' })}
            accordionWrapperClassName="my-10 border-red-700"
          >
            <div className="mt-4">
              <Dangerzone deleteCallback={() => {
                  deleteAssetById(data?.id); 
                  history.push(ROUTES.HOME);
              }}
              />
            </div>
          </CustomAccordion>
        )}

        <ModalContainer
          isModalOpened={openedModalType === 'imageUploader'}
          onClose={() => setOpenedModalType('')}
        >
          <ImageScanner
            onClose={() => setOpenedModalType('')}
            addLocalImage={() => {}}
            assetId={data?.id ?? 0}
          />
        </ModalContainer>

        <ServerFormError
          title={t('error-request')}
          requestError={error as ServerError}
          isError={isError}
        />
      </FormWrapper>
    </ModalContainer>
  );
};

export default React.memo(TagForm);
