import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IMultiSelectOptions } from 'src/types';
import { handleChangeMultiSelect } from 'src/helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from 'src/components/CustomSelect';
import { useGetBatchesQuery } from 'src/store/services/batchApi';
import { IBatchData } from 'src/store/services/batchApi/batchTypes';
import ScanButton from 'src/views/Home/components/ScanButton';

interface IBatchesSelect {
  batches: IBatchData[];
  setFieldValue: any;
}

const BatchesSelect = ({ batches, setFieldValue }: IBatchesSelect) => {
  const { data, isSuccess } = useGetBatchesQuery({});
  const { t } = useTranslation('tag');
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const batchOptions = useMemo(
    () => (isSuccess ? data.map((batch) => ({ value: batch, label: batch.name })) : []),
    [data, isSuccess],
  );

  const selectedBatchesValue = useMemo(
    () => batchOptions.filter((option) => batches?.find((batch) => batch.id === option.value.id)),
    [batchOptions, batches]
  );

  useEffect(() => {
    setHasValue(!!batches.length || !!selectedBatchesValue.length); 
  }, [batches, selectedBatchesValue]);

  const handleSelectChange = (option: IMultiSelectOptions) => {
    handleChangeMultiSelect({ option, field: 'batches', setFieldValue });
    setHasValue(!!option.length); 
  };

  const handleScanResult = (_field: string, scannedText: string) => {
    if (!isSuccess || !data) return;

    if (scannedText.startsWith('http')) {
      try {
        const url = new URL(scannedText);
        const pathParts = url.pathname.split('/');
        const guid = pathParts[pathParts.length - 1];

        const scannedBatch = data.find(batch => batch.guid === guid);
        if (scannedBatch && !batches.find(b => b.id === scannedBatch.id)) {
          setFieldValue('batches', [...batches, scannedBatch]);
          setHasValue(true);
        }
        return;
      } catch (error) {
        console.error('Error parsing batch URL:', error);
      }
    }

    const scannedBatch = data.find(batch => batch.guid === scannedText);
    if (scannedBatch && !batches.find(b => b.id === scannedBatch.id)) {
      setFieldValue('batches', [...batches, scannedBatch]);
      setHasValue(true);
    }
  };

  return (
    <div className="relative flex items-center gap-3 w-full">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={() => setIsScannerOpen(true)}
        handleScannerClose={() => setIsScannerOpen(false)}
        scannerMode="batchQR"
        setFieldValue={handleScanResult}
      />
      <div className="relative w-full">
        <CustomSelect
          placeholder=""
          value={selectedBatchesValue}
          options={batchOptions}
          isMulti
          onSelect={handleSelectChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <label
          className={`absolute left-[10px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
            isFocused || hasValue ? '!-top-0 text-xs' : 'top-[50%] -translate-y-1/2 text-base'
          }`}
          style={{ width: 'calc(100% - 40px)' }}
        >
          {t('edit_tag_batches_placeholder')}
        </label>
      </div>
    </div>
  );
};

export default React.memo(BatchesSelect);