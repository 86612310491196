import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { IAsset } from '../../../store/services/assetsApi/assetsTypes';

import { ReactComponent as IconQR } from 'src/assets/home/IconQR2.svg';
import ChipWithIcon from 'src/components/ChipWithIcon';
import { useTranslation } from 'react-i18next';
import TagCategories from 'src/views/Tag/components/TagContent/components/TagCategories';
import { useGetCategoriesQuery } from 'src/store/services/categoryApi';

import { ReactComponent as IconInitialized } from 'src/assets/IconInitialized.svg';
import { ReactComponent as IconInUse } from 'src/assets/IconInUse.svg';
import { ReactComponent as IconTransport } from 'src/assets/IconTransport.svg';
import { ReactComponent as IconService } from 'src/assets/IconService.svg';
import { ReactComponent as IconWarehouse } from 'src/assets/IconWarehouse.svg';
import { ReactComponent as IconWarranty } from 'src/assets/footer-menu/IconLicense.svg';
import { ReactComponent as IconDiscarded } from 'src/assets/footer-menu/IconDelete.svg';

interface IBatchAssetRow {
  asset: IAsset;
  showLocation?: boolean;
}

export const getStateIcon = (state: string | null,  className: string) =>
  state === 'PROVISIONED' ? <IconInitialized className={" fill-gray-400 " + className } /> :
  state === 'COMMISSIONED' ? <IconInUse className={" fill-white " + className} /> :
  state === 'TRANSPORT' ? <IconTransport className={" fill-white " + className} /> :
  state === 'DISCARDED' ? <IconDiscarded className={" fill-orange-700 " + className} /> :
  state === 'SERVICE' ? <IconService className={className} /> :
  state === 'STORED' ? <IconWarehouse className={" fill-orange-500 " + className} /> :
  <IconInitialized className={className} />;

export const getStateStyle = (state: string | null) =>
    state === 'PROVISIONED' ? " bg-gray-100 text-gray-700 border border-dashed " :
    state === 'COMMISSIONED' ? " bg-green-600 text-white font-bold " :
    state === 'TRANSPORT' ? " bg-gray-700 text-white font-bold " :
    state === 'DISCARDED' ? " text-orange-700 border border-orange-700 border-dashed " :
    state === 'SERVICE' ? " bg-yellow-200 font-bold " :
    state === 'STORED' ? " text-orange-500 border border-orange-400 border-2 font-bold " :
    "";

const AssetTableRow = ({ asset, showLocation }: IBatchAssetRow) => {
  const history = useHistory();
  const handleOpenSingleAsset = useCallback(() => {
    history.push(`/tag/${asset.tag}`);
  }, [asset, history]);
  
  const { t } = useTranslation('tag'); 
  const { data: categoryList, isSuccess: isCategoriesSuccess } = useGetCategoriesQuery({});

  return (
    <tr onClick={handleOpenSingleAsset} className="cursor-pointer even:bg-gray-50 odd:bg-gray-100">
      <td className="text-base p-[0.5em] text-left relative">
        {asset.name}
        
        </td>
        {showLocation && (
        <td className="hidden xl:table-cell p-[0.5em] text-left relative">
        {asset.locationName}
        </td>
        )}        
      <td className="hidden md:table-cell text-[#555] p-[0.5em] text-left relative w-1/3">
                      <TagCategories
                        properties={{}}
                        selectedCategories={asset.categories}
                        connectedCategories={[]}
                        categoryList={categoryList}
                        isCategoriesSuccess={isCategoriesSuccess}
                        />
      </td>
      <td className="hidden xl:table-cell text-[#555] p-[0.5em] text-left relative  w-32">{asset.warrantyExpiration && (
                  <ChipWithIcon icon={<IconWarranty />} text={asset.warrantyExpiration }   className={"rounded shadow-md w-32  py-1 px-1  m-auto text-[1em] font-bold" } />

      )}</td>
      <td className="text-[#555] p-[0.5em] text-center relative  w-32 ">
          <ChipWithIcon icon={getStateIcon(asset.state, "w-4 h-4 ")} text={t(`asset-state-${asset.state.toLowerCase()}-option`)}   className={"rounded shadow-md w-32  py-1 px-1  m-auto text-[0.9em]" + getStateStyle(asset.state)} />
      </td>
      <td className=" text-[#555] p-[0.5em] text-center relative  w-28">
         <ChipWithIcon icon={<IconQR className="w-4 h-4 fill-gray-500" />} text={asset.tag}   className=' border rounded font-mono text-[0.9em] m-auto py-1 px-0 m-0' />
      </td>
    </tr>
  );
};

export default AssetTableRow;
