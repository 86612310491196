import React from 'react';
import { useTranslation } from 'react-i18next';

interface ICategoryBadge {
  category: string;
  handleCategoryClick: (category: string) => void;
  badgeType?: 'connected' | 'selected';
}

const CategoryBadge = ({
  category,
  handleCategoryClick,
  badgeType = 'connected',
}: ICategoryBadge) => {
  const { t } = useTranslation('tag');
  const selectedClassName =
    'inline-block py-1 px-2  mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-category-badge-foreground-color text-center rounded-xl cursor-pointer';
  const connectedClassName = `${selectedClassName} bg-transparent border-2 border-dashed border-category-badge`;

  return (
    <div key={category} className="lg:w-auto p-1 rounded-lg">
      <button
        type="button"
        onClick={() => handleCategoryClick(category)}
        className={badgeType === 'connected' ? connectedClassName : selectedClassName}
      >
        {t(`category-${category.toLowerCase().split(' ').join('-')}`, { defaultValue: category })}
      </button>
    </div>
  );
};

export default CategoryBadge;
