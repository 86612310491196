import React from 'react';
import { useHistory } from 'react-router';
import { IBatchData } from '../../../../../store/services/batchApi/batchTypes';
import { useTranslation } from 'react-i18next';
import { batchState, batchType } from 'src/store/services/batchApi/batchTypes';

import { ReactComponent as IconRegistration } from 'src/assets/home/IconRegistration.svg';
import { ReactComponent as IconTransport } from 'src/assets/IconTransport.svg';
import { ReactComponent as IconService } from 'src/assets/IconService.svg';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconPlanned } from 'src/assets/IconPlanned.svg';
import { ReactComponent as IconStart } from 'src/assets/IconStart.svg';
import { ReactComponent as IconDone } from 'src/assets/IconDone.svg';
import { ReactComponent as IconCounter } from 'src/assets/IconCounter.svg';
import ChipWithIcon from '../../../../../components/ChipWithIcon';

interface IBatchesBadge {
  batches: IBatchData[];
}

const BatchesBadge = ({ batches }: IBatchesBadge) => {
  const history = useHistory();

  const { t } = useTranslation('tag');

  const handleBatchClick = (batchId: number) => {
    history.push(`/batch/${batchId}`);
  };


  const getStyle = (state: batchState) => {
    const style = state === 'IN_PROGRESS' ? { border: '2px solid #5F9548' } : 
    state === 'COMPLETED' ? { border: '2px solid #4C443C' } :
    state === 'PLANNED' ? { border: '2px dashed #5F9548' } : {};
    return style;
  }

  const getTypeIcon = (type: batchType, className: string) => 
    type === 'ASSET_CREATION' ? <IconAdd className={className} /> :
    type === 'REGISTRATION' ? <IconRegistration className={className} /> :
    type === 'TRANSPORT' ? <IconTransport className={className} /> :
    type === 'SERVICE' ? <IconService className={className} /> :
    type === 'COUNT' ? <IconCounter className={className} /> :
    <IconLayers className={className} />;

  const getStateIcon = (state: batchState, className: string) => 
    state === 'PLANNED' ? <IconPlanned className={className} /> :
    state === 'IN_PROGRESS' ? <IconStart className={className} /> :
    state === 'COMPLETED' ? <IconDone className={className} /> :
    <></>;


  
  return (
    <>
      {!!batches?.length &&
        batches.map((batch, index) => (
          <div
            onClick={() => handleBatchClick(batch.id)}
            className=" rounded-sm border-gray-200 shadow-xl py-2  p-1 cursor-pointer "
            key={`${index}batch`}
          >
            <h5 className="text-center text-gray-400 text-[0.8em]">{t('batch', {'ns': 'tag'})}</h5>
            <p className="text-center">{(batch.name?.length) ? batch.name?.replace(/ /g, '\u00a0').substring(0, 15) : batch.id}</p>
            <ChipWithIcon icon={getTypeIcon(batch.type!, "w-5 h-5 fill-gray-500")} text={t(`batch-type-${batch.type!.toLowerCase()}-option`, {ns: 'batches'}).toUpperCase()}   className=' w-32 border-dashed border bg-white py-1 px-1 mx-auto  my-2 text-[0.8em]' />
            <ChipWithIcon icon={getStateIcon(batch.state, "w-5 h-5 fill-gray-500")} text={t(`batch-state-${batch.state.toLowerCase()}-option`, {ns: 'batches'}).toUpperCase()}  className='  w-32 py-1 px-1 my-2 mx-auto bg-white text-[0.8em]' style={getStyle(batch.state)} />
          </div>
        ))}
    </>
  );
};

export default React.memo(BatchesBadge);
