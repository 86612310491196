import React, { useCallback, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import i18n from './i18next/i18next';

import { ROUTES } from './constants/routes';

import Layout from './components/Layout';
import TagProduct from 'src/views/Tag';
import { useAppDispatch, useAppSelector } from 'src/helpers/reduxCommon';
import { setAuthToken } from 'src/store/slices/auth.slice';
import history from 'src/utils/history';
import Loading from 'src/components/Loading';
import Home from 'src/views/Home';
import Location from 'src/views/Location';
import LocationSingle from 'src/views/LocationSingle';
import Batches from 'src/views/Batches';
import BatchSingle from 'src/views/BatchSingle';
import Events from 'src/views/Events';
import Search from 'src/views/Search';
import PrivateRoute from 'src/components/HighOrderedComponents/PrivateRoute';
import VersionCheck from 'src/components/VersionCheck';
import useFetchUserLanguage from './hooks/useFetchUserLanguage';
import { setActiveOrg } from 'src/store/slices/activeOrg.slice';
import { User } from '@auth0/auth0-spa-js';

// styles
import useChangeTheme from './hooks/useChangeTheme';
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';

initFontAwesome();

const App = () => {
  const dispatch = useAppDispatch();
  const isAuthLoading = useAppSelector((state) => state.auth.isLoading);
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, error } = useAuth0();
  const { language, loading } = useFetchUserLanguage(); 

  useChangeTheme();

  const getToken = useCallback(async () => {
    if (!isLoading) {
      let token = '';
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        let organizations = (user as User)['https://sirk.app/organizations'] as string[]
        dispatch(setActiveOrg(organizations[0]))
      }
      dispatch(setAuthToken(token));
    }
  }, [dispatch, getAccessTokenSilently, isLoading, isAuthenticated, user]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  useEffect(() => {
    if (!loading) {
      i18n.changeLanguage(language); 
    }
  }, [language, loading]);


  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="h-full z-0">
        <Switch>
          <Route exact={true} path={ROUTES.HOME} component={Home} />
          <Route path={ROUTES.TAG} component={TagProduct} />
          <Layout>
            <Switch>
              <PrivateRoute path={ROUTES.BATCH_SINGLE} component={BatchSingle} />
              <PrivateRoute path={ROUTES.BATCHES} component={Batches} />
              <PrivateRoute path={ROUTES.SINGLE_LOCATION} component={LocationSingle} />
              <PrivateRoute path={ROUTES.LOCATION} component={Location} />
              <PrivateRoute path={ROUTES.EVENTS} component={Events} />
              <PrivateRoute path={ROUTES.SEARCH} component={Search} />
              <PrivateRoute path={ROUTES.CATEGORIES} component={() => <> {'error page'}</>} />
              <Route path={ROUTES.ERROR} component={() => <>{'error page'}</>} />
            </Switch>
          </Layout>
        </Switch>
        <VersionCheck />
      </div>
    </Router>
  );
};

export default App;
