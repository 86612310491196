import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../../ButtonWithIcon';
import AddBatchModal from './AddBatchModal';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import DownloadButton from '../../DownloadButton';
import { ReactComponent as IconDownload } from 'src/assets/IconDownload.svg';
import { useLazyGetBatchTagsQuery } from '../../../store/services/batchApi';


const CreateBatchModal = () => {
  const { t } = useTranslation('layout');
  const [isModalOpened, setModalOpened] = useState(false);

  
  const [triggerBatchQR, { data: fileUrl, isFetching }] = useLazyGetBatchTagsQuery();
  

  const handleCloseCreatingModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  return (
    <div className="flex">
      <ButtonWithIcon
        className="flex items-center justify-center gap-1 py-[10px] px-5 rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
        icon={<IconAdd className="w-6 h-6 fill-tag" />}
        text={t('batch-creation-text')}
        onClick={() => setModalOpened(true)}
      />
      <DownloadButton 
         icon={<IconDownload className="w-6 h-6 fill-tag" />}
         text={t('download-qr-codes', {'ns': 'batches'})}
         downloadFilename='batch-qr-codes.csv'
         trigger={triggerBatchQR}
         fileUrl={fileUrl}
         isFetching={isFetching}

      />
      
      <AddBatchModal
        isOpened={isModalOpened}
        onClose={handleCloseCreatingModal}
        creationType="fromNewObjects" 
      />
    </div>
  );
};

export default React.memo(CreateBatchModal);
