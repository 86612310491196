export const skippedScannerExceptions: Record<string, boolean> = {
  'QR code parse error, error = NotFoundException: No MultiFormat Readers were able to detect the code.':
    true,
  'QR code parse error, error = N: No MultiFormat Readers were able to detect the code.': true,
  'QR code parse error, error = A: No MultiFormat Readers were able to detect the code.': true,
  'QR code parse error, error = A: No Multiformat Readers were able to detect the code.': true,
  'QR code parse error, error = O: No MultiFormat Readers were able to detect the code.': true,
  'QR code parse error, error = No barcode or QR code detected.': true,
  'QR code parse error, error = _: No MultiFormat Readers were able to detect the code.': true,
};
