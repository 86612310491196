import { IOptions } from 'src/types';

export const batchTypeOptions: IOptions[] = [
    { value: 'ASSET_CREATION', label: 'Asset creation' },
    { value: 'REGISTRATION', label: 'Registration' },
    { value: 'TRANSPORT', label: 'Transport' },
    { value: 'SERVICE', label: 'Service' },
    { value: 'COUNT', label: 'Count' },
    { value: 'OTHER', label: 'Other' },
];
