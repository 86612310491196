import React, { useCallback, useEffect, useState } from 'react';
import { useGetBatchesQuery } from '../../store/services/batchApi';
import Loading from '../../components/Loading';
import { IBatchData } from '../../store/services/batchApi/batchTypes';
import BatchesFilter from './components/BatchesFilter';
import BatchesTable from './components/BatchesTable';
import { useTranslation } from 'react-i18next';
import CreateBatchModal from '../../components/Modals/Batch/CreateBatchModal';
import ScanButton from '../Home/components/ScanButton';

const Batches = () => {
  const [selectedStates, setSelectedStates] = useState<string[]>(['PLANNED', 'IN_PROGRESS']);
  const [currentPage, setCurrentPage] = useState(() => {
    const saved = localStorage.getItem('batches-page');
    return saved ? parseInt(saved, 10) : 1;
  });
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, isLoading } = useGetBatchesQuery({
    ...(selectedStates.length > 0 && { states: selectedStates.join(',') }),
    ...(selectedTypes.length > 0 && { types: selectedTypes.join(',') }),
  });

  const [filteredBatches, setFilteredBatches] = useState<IBatchData[] | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && data) {
      const filtered = searchTerm
        ? data.filter(
            (batch) =>
              batch.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              batch.description?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : data;
      setFilteredBatches(filtered);
    }
  }, [isLoading, data, searchTerm]);

  useEffect(() => {
    localStorage.setItem('batches-page', currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem('batches-page', currentPage.toString());
  }, [currentPage]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);

  const handleScanFilter = useCallback((data: IBatchData[]) => {
    setFilteredBatches(data);
    handleCloseScanner();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <BatchesFilter
        batches={data}
        setBatches={setFilteredBatches}
        setSelectedStates={setSelectedStates}
        setSelectedTypes={setSelectedTypes}
      />

      <div className="flex items-center space-x-4 pb-20">
        <ScanButton
          isOpen={isScannerOpened}
          handleScannerOpen={handleOpenScanner}
          handleScannerClose={handleCloseScanner}
          scannerMode="batch"
          setFieldValue={handleScanFilter}
          buttonText={t('scanner-batch-button', { ns: 'tag' })}
          variant='batch'
        />
      </div>

      {filteredBatches && (
        <BatchesTable 
          data={filteredBatches} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      
      <CreateBatchModal />
    </div>
  );
};

export default Batches;
