import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isLoading: boolean;
  token: string;
  returnTo: string | null;
}

const initialState: AuthState = {
  isLoading: true,
  token: '',
  returnTo: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isLoading = false;
    },
    setReturnTo: (state, action: PayloadAction<string>) => {
      state.returnTo = action.payload;
    },
    clearReturnTo: (state) => {
      state.returnTo = null;
    }
  }
});

export const { setAuthToken, setReturnTo, clearReturnTo } = authSlice.actions;
export default authSlice.reducer;
