import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ModalContainer from '../../components/Modals/components/ModalContainer';
import Scanner from '../../components/Scanner/Scanner';
import Footer from '../../views/Landing/components/Footer';
import Navigation from '../Navigation';

interface LayoutProps {
  children: React.ReactNode; 
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const currentTheme = process.env.REACT_APP_THEME || 'defaultTheme';
  const params = new URLSearchParams(location.search);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(!!params.get('isOpenScanner'));

  const handleScannerOpen = useCallback(() => setIsScannerOpen(true), []);
  const handleScannerClose = useCallback(() => setIsScannerOpen(false), []);

  return (
    <div className="relative h-auto w-full flex flex-col layout-container pb-20 xl:w-4/5 2xl:w-2/3 xl:mx-auto">
     
      <Navigation
        handleScannerOpen={handleScannerOpen}
        currentTheme={currentTheme}
      />

      {children}

      <Footer />


      <ModalContainer isModalOpened={isScannerOpen} onClose={handleScannerClose}>
        <Scanner onClose={handleScannerClose} />
      </ModalContainer>
    </div>
  );
};

export default Layout;
