import { apiRtk, RTK_TAGS } from '../index';
import { REQUEST } from '../../storeTypes';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import {
  IAsset,
  IEditAssetBody,
  IGetAssetFileBody,
  ICreateAssetBody,
  IGetAssetFileByParamsBody,
} from './assetsTypes';

export const assetApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAsset: build.query({
      query: (params) => ({
        url: '/asset',
        params,
        headers: { Accept: 'application/json' },
        method: REQUEST.GET,
      }),
      transformResponse: (response: IAsset[], meta: BaseQueryMeta<any>) => {
        return {
          assets: response,
          xPageTotal: meta.response.headers.get('X-Page-Total'),
          xEntriesTotal: meta.response.headers.get('x-entries-total')
        };
      },
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAssetById: build.query({
      query: (id: string) => ({
        url: `/asset/${id}`,
        method: REQUEST.GET,
      }),
      transformResponse: (response: IAsset) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAssetFile: build.query({
      query: ({ params, headers }: IGetAssetFileByParamsBody) => ({
        url: '/asset',
        method: REQUEST.GET,
        params,
        headers,
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => window.URL.createObjectURL(response),
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAssetFileByBatchId: build.query({
      query: ({ id, headers, params }: IGetAssetFileBody) => ({
        url: `/asset?batchId=${id}`,
        method: REQUEST.GET,
        headers,
        params,
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => window.URL.createObjectURL(response),
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAssetFileByLocationId: build.query({
      query: ({ id, headers, params }: IGetAssetFileBody) => ({
        url: `/asset?locationId=${id}`,
        method: REQUEST.GET,
        headers,
        params,
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => window.URL.createObjectURL(response),
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    editAsset: build.mutation({
      query: ({ assetId, body, headers }: IEditAssetBody) => ({
        url: `/asset/${assetId}`,
        method: REQUEST.PATCH,
        body,
        headers,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
        return error;
      },
      invalidatesTags: () => [{ type: RTK_TAGS.TAG }, { type: RTK_TAGS.EVENTS }],
    }),
    createAsset: build.mutation({
      query: ({ body, headers }: ICreateAssetBody) => ({
        url: '/asset',
        method: REQUEST.POST,
        body,
        headers,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      invalidatesTags: () => [{ type: RTK_TAGS.TAG }, { type: RTK_TAGS.EVENTS }],
    }),
    deleteAsset: build.mutation({
      query: (assetId) => ({
        url: `/asset/${assetId}`,
        method: REQUEST.DELETE,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', {error, meta, arg});
      },
      invalidatesTags: () => [{type: RTK_TAGS.TAG}, {type: RTK_TAGS.EVENTS}],
    }),
    importCSVToAsset: build.mutation({
      query: (body: FormData) => ({
        url: 'asset/import',
        method: REQUEST.POST,
        body,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
  }),
});

export const {
  useEditAssetMutation,
  useLazyGetAssetFileByBatchIdQuery,
  useLazyGetAssetFileByLocationIdQuery,
  useCreateAssetMutation,
  useGetAssetQuery,
  useLazyGetAssetQuery,
  useGetAssetByIdQuery,
  useLazyGetAssetFileQuery,
  useDeleteAssetMutation,
  useImportCSVToAssetMutation,
} = assetApi;
