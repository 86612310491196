import React, { useMemo, useState } from 'react';
import { useGetAssetQuery } from '../../../../store/services/assetsApi';
import AssetsTable from '../../../../components/AssetsTable';
import { useLazyGetAssetFileQuery } from '../../../../store/services/assetsApi';
import DownloadDocuments from 'src/components/DownloadDocumentButton/components/DownloadDocuments';

interface ISearchResult {
  searchType?: string | null;
  searchValue?: string | null;
  search?: string | null;
}

const SearchResult = ({ searchType, searchValue, search }: ISearchResult) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;

  const queryVariables = useMemo(() => {
    if (searchType && searchValue && search) {
      return {
        search,
        [searchType]: searchValue,
        pageSize,
        pageOffset: currentPage,
      };
    } else if (searchType && searchValue) {
      return {
        [searchType]: searchValue,
        pageSize,
        pageOffset: currentPage,
      };
    } else if (search) {
      return {
        search,
        pageSize,
        pageOffset: currentPage,
      };
    }
    return null;
  }, [search, searchType, searchValue, currentPage, pageSize]);

  const { data } = useGetAssetQuery(queryVariables, {
    skip: !search && !searchType && !searchValue,
  });

  const hasSearchResults =
    (search && !!data?.assets.length) || (searchType && searchValue && !!data?.assets.length);


  const [
    triggerFilteredPDF,
    { data: pdfFilteredData, isLoading: isPdfDataLoading, isFetching: isPdfFetching },
  ] = useLazyGetAssetFileQuery();
  
  const [
    triggerFilteredCSV,
    { data: csvFilteredData, isLoading: isCsvDataLoading, isFetching: isCsvFetching },
  ] = useLazyGetAssetFileQuery();

  return hasSearchResults ? (
    <section className="mt-4">
      <AssetsTable data={data} currentPage={currentPage} setCurrentPage={setCurrentPage} showLocation={true}/>
      <DownloadDocuments
        id={String(data.assets.length)}
        type="search"
        isCsvFetching={isCsvFetching}
        isPdfFetching={isPdfFetching}
        csvFileUrl={csvFilteredData}
        pdfFileUrl={pdfFilteredData}
        csvTrigger={triggerFilteredCSV}
        pdfTrigger={triggerFilteredPDF}
        params={search ? { search: search } : searchType && { [searchType]: searchValue }}
      />
    </section>
  ) : null;
};

export default SearchResult;