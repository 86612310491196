import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import { ReactComponent as LoadFileIcon } from 'src/assets/upload_file_filled.svg';
import { IDocument } from 'src/store/services/tagsApi/tagTypes';

interface IDocumentPreviewerProps {
  tagDocuments: IDocument[];
  setTagDocuments: React.Dispatch<React.SetStateAction<IDocument[]>>;
}

const DocumentPreviewer: React.FC<IDocumentPreviewerProps> = ({
  tagDocuments,
  setTagDocuments,
}) => {
  const { t } = useTranslation();

  const handleDeleteDocument = (documentToDelete: IDocument) => {
    setTagDocuments((prevDocs) =>
      prevDocs.filter((doc) => doc.url !== documentToDelete.url)
    );
  };

  const getFileName = (urlLink: string) => {
    const regex = /\/([^/?]+)\?/;
    const match = urlLink.match(regex);
    return match && match[1] ? match[1] : 'invalid name';
  };

  const documentOnly = tagDocuments.filter(
    (document) => document.documentType === 'DOCUMENT'
  );

  return (
    <div className="mt-8">
      <label className="block text-gray-500 text-sm font-medium mb-2">
        {t('Uploaded Documents')}
      </label>
      <div className="p-4 border border-gray-300 rounded-lg">
        <div className="flex flex-wrap gap-4 justify-center">
          {documentOnly.map((document) => (
            <div key={document.url} className="w-48">
              <div className="flex items-center gap-2 p-3 border border-gray-300 rounded-lg hover:bg-gray-100 w-full">
                <LoadFileIcon className="w-6 h-6" />
                <p className="text-sm truncate">{getFileName(document.url)}</p>
              </div>
              <div className="flex justify-center mt-2">
                <button
                  onClick={() => handleDeleteDocument(document)}
                  className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                  aria-label={`Delete document ${getFileName(document.url)}`}
                >
                  <IconDelete className="w-4 h-4 fill-white" />
                  <span className="text-sm">{t('Delete')}</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentPreviewer;
